import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingBackdropComponent} from './loading-backdrop.component';
import {LoadingModule} from '../loading/loading.module';
import {DialogModule} from 'primeng/dialog';
import {BlockUIModule} from 'primeng/blockui';


@NgModule({
    imports: [
        CommonModule,
        LoadingModule,
        DialogModule,
        BlockUIModule,
    ],
    declarations: [
        LoadingBackdropComponent
    ],
    exports: [
        LoadingBackdropComponent
    ],
    providers: []
})
export class LoadingBackdropModule {
}
